import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../Header/Header.css';
import { FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50); // Adjust the value as needed
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const callAdmin = () => {
    window.location.href = 'tel:+91000000000';
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <div className={`container-h ${scrolled ? 'scrolled' : ''}`}>
      <nav className="navbar">
        <Link to="/" onClick={closeMobileMenu}>
          <img src='logo3.png' className={`logo-h ${scrolled ? 'logo-h-small' : ''}`} alt='logo' />
        </Link>
        <div className="hamburger" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes className='ham' /> : <FaBars className='ham2' />}
        </div>
        <ul className={`navbar-list ${isMobileMenuOpen ? 'mobile-open' : ''}`}>
          <div className="navbar-links">
            <li className="navbar-items"><Link to="/" onClick={closeMobileMenu}>Home</Link></li>
            <li className="navbar-items"><Link to="/team2" onClick={closeMobileMenu}>Team</Link></li>
            <li className="navbar-items"><Link to="/sponsors" onClick={closeMobileMenu}>Sponsors</Link></li>
            <li className="navbar-items"><Link to="/contact" onClick={closeMobileMenu}>Contact</Link></li>
          </div>
          <div className="nav-button">
            <p className='sc-p' onClick={callAdmin}>Schedule a Call</p>
          </div>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
