import React from 'react';
import '../Spinner/Spinner.css'; 

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
     {/*-<div className="spinner"></div>-*/} 
    </div>
  );
};

export default LoadingSpinner;
