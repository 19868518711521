import React, { useState } from 'react';
import '../Chatbox/Chatbox.css';
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const Chatbox = () => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');

 


  const openFacebook = () => {
    const whatsappUrl = 'https://www.facebook.com/people/INDO-KINGS/61561700589842/';
    window.open(whatsappUrl, '_blank', 'noopener,noreferrer');
  };

  const openInsta = () => {
    const instagramUrl = 'https://www.instagram.com/indokings1/';
    window.open(instagramUrl, '_blank', 'noopener,noreferrer');
  };

  const openLinkedin = () => {
    const linkedinUrl = 'https://www.linkedin.com/in/indo-kings-88750131b/';
    window.open(linkedinUrl, '_blank', 'noopener,noreferrer');
  };

  const openTwitter = () => {
    const twitterUrl = 'https://x.com/kings_indo12766';
    window.open(twitterUrl, '_blank', 'noopener,noreferrer');
  };
  return (
    <>
      <div className="chat-component">
        <div className="messages">
          {/* Render messages here */}
        </div>
        <FaFacebook className='icon-chat' onClick={openFacebook} />
        <FaInstagramSquare className='icon-chat' onClick={openInsta} />
        <FaLinkedin className='icon-chat' onClick={openLinkedin} />
        <FaSquareXTwitter className='icon-chat' onClick={openTwitter} />
      </div>
    
    </>
  );
};

export default Chatbox;
