import React, { Suspense, lazy, useEffect } from "react";
import { useLocation, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Chatbox from "./Components/Chatbox/Chatbox";
import Spinner from "./Components/Spinner/Spinner"

// Use React.lazy to dynamically import components
const Home = lazy(() => import("./Components/Home/Home"));
const Team = lazy(() => import("./Components/Team/Team"));
const Team2 = lazy(() => import("./Components/Team2/Team2"));
const Contact = lazy(() => import("./Components/Contact/Contact"));
const Sponsor = lazy(() => import("./Components/Sponsorship/Sponsor"));


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <Header />
      <ScrollToTop />
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/team" element={<Team />} />
          <Route path="/team2" element={<Team2 />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/sponsors" element={<Sponsor />} />
         
        </Routes>
      </Suspense>
      <Footer />
      <Chatbox />
    </Router>
  );
}

export default App;
