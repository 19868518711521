import React from 'react'
import '../Footer/Footer.css'
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <>
       <footer className="footer">
  <div className="container-f">
    <div className="footer-content">
      <div className="footer-logo">
    <Link to="/">    <img src='logo3.png' alt='logo' className='logo-f'/></Link>
      </div>
      <div className="footer-links">
        <ul className="footer-menu">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/team2">Team</Link></li>
          <li><Link to="/sponsors">Sponsors</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </div>
      <div className="footer-social">
      <p>Follow Us</p>
        <ul className="social-icons">
          <li><a target='_blank' href="https://www.facebook.com/people/INDO-KINGS/61561700589842/"><FaFacebook className='ic-fa'/></a></li>
          <li><a target='_blank' href="https://www.instagram.com/indokings1/"><FaInstagramSquare className='ic-fa'/></a></li>
          <li><a target='_blank' href="https://www.linkedin.com/in/indo-kings-88750131b/"><FaLinkedin className='ic-fa'/></a></li>
          <li><a target='_blank' href="https://x.com/kings_indo12766"><FaSquareXTwitter className='ic-fa'/></a></li>
        </ul>
      </div>
    </div>
    <div className="footer-bottom">
    <p>© 2024 Indokings. All rights reserved.  Powered by <a href="https://www.infogainsoft.com" className='in' target="_blank">Infogainsoft</a></p>

    </div>
  </div>
</footer>

    </>
  )
}

export default Footer